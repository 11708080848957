<template>
  <div class="section">
    <div v-if="reservation" class="container">
      <div class="columns is-large-gap">
        <div class="column is-6">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Room Information') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditRoomInfo
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Type Code') }}
                </div>
                <div>{{ reservedRoom.roomCode }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Type Name') }}
                </div>
                <div>{{ reservedRoom.roomName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Per Room Pax Count') }}
                </div>
                <div>{{ totalPax }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Number of children (total)') }}
                </div>
                <div>{{ Number(numberOfChildren) + Number(numberOfInfrant) }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Pax Female Count') }}
                </div>
                <div>{{ roomInfo.paxFemaleCount }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Pax Male Count') }}
                </div>
                <div>{{ roomInfo.paxMaleCount }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child Other Count') }}
                </div>
                <div>{{ roomInfo.childOtherCount }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child Other Name') }}
                </div>
                <div>{{ roomInfo.childOtherName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child A 70 Count') }}
                </div>
                <div>{{ roomInfo.childA70Count }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child A 70 Name') }}
                </div>
                <div>{{ roomInfo.childA70Name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child B 50 Count') }}
                </div>
                <div>{{ roomInfo.childB50Count }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child B 50 Name') }}
                </div>
                <div>{{ roomInfo.childB50Name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child C 30 Count') }}
                </div>
                <div>{{ roomInfo.childC30Count }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child C 30 Name') }}
                </div>
                <div>{{ roomInfo.childC30Name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child D None Count') }}
                </div>
                <div>{{ roomInfo.childDNoneCount }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child D None Name') }}
                </div>
                <div>{{ roomInfo.childDNoneName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child E Count') }}
                </div>
                <div>{{ roomInfo.childECount }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child E Name') }}
                </div>
                <div>{{ roomInfo.childEName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child F Count') }}
                </div>
                <div>{{ roomInfo.childFCount }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child F Name') }}
                </div>
                <div>{{ roomInfo.childFName }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Room Option') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditRoomOption
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Option Date') }}
                </div>
                <div>{{ roomOption.date }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Option Per Category') }}
                </div>
                <div>{{ roomOption.perCategory }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Option Name') }}
                </div>
                <div>{{ roomOption.name }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Option Count') }}
                </div>
                <div>{{ roomOption.count }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Option Code') }}
                </div>
                <div>{{ roomOption.code }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Option Rate') }}
                </div>
                <div>{{ roomOption.rate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Option Category') }}
                </div>
                <div>{{ roomOption.category }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Total Option Rate') }}
                </div>
                <div>{{ roomOption.totalRate }}</div>
              </div>
            </div>
          </div>
        </div>

        <div class="column is-6">
          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Guest Information') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditGuestInfo
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Name Single Byte') }}
                </div>
                <div>{{ guest.nameSingleByte }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Emergency Phone Number') }}
                </div>
                <div>{{ guest.emergencyPhone }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Surname') }}
                </div>
                <div>{{ guest.surname }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Email') }}
                </div>
                <div>{{ guest.email }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Kanji Name') }}
                </div>
                <div>{{ guest.kanjiName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest State Province') }}
                </div>
                <div>{{ guest.stateProvince }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Phone Number') }}
                </div>
                <div>{{ guest.phone }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Guest Zip') }}
                </div>
                <div>{{ guest.zip }}</div>
              </div>
            </div>
          </div>

          <div class="columns columns-table is-gapless is-mobile is-size-7 m-b-0">
            <div class="column">
              <div class="th">
                {{ $t('Room Rate Information') }}
              </div>
            </div>
            <div class="column has-text-right">
              <div class="th">
                <Modal>
                  <template #default="{ hide }">
                    <EditRoomRateInfo
                      :value="reservation"
                      @close="
                        () => {
                          hide();
                          asyncData();
                        }
                      "
                      @cancel="hide"
                    />
                  </template>
                  <template #handler="{ show }">
                    <a class="has-text-white" @click.prevent="show">
                      <IconBase width="13" height="13" icon-name="edit">
                        <IconEdit />
                      </IconBase>
                      {{ $t('Edit') }}</a>
                  </template>
                </Modal>
              </div>
            </div>
          </div>
          <div class="columns columns-table is-gapless is-multiline is-size-7 m-b-0">
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Date') }}
                </div>
                <div>{{ roomRateInfo.roomDate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child A70 Rate Name') }}
                </div>
                <div>{{ roomRateInfo.childA70RateName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Per Pax Rate') }}
                </div>
                <div>{{ roomRateInfo.perPaxRate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child B50 Rate Name') }}
                </div>
                <div>{{ roomRateInfo.childB50RateName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child A70 Rate') }}
                </div>
                <div>{{ roomRateInfo.childA70Rate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child D Rate') }}
                </div>
                <div>{{ roomRateInfo.childDRate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child B50 Rate') }}
                </div>
                <div>{{ roomRateInfo.childB50Rate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child D Rate Name') }}
                </div>
                <div>{{ roomRateInfo.childDRateName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child C Rate') }}
                </div>
                <div>{{ roomRateInfo.childCRate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child C Rate Name') }}
                </div>
                <div>{{ roomRateInfo.childCRateName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child E Rate') }}
                </div>
                <div>{{ roomRateInfo.childERate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child E Rate Name') }}
                </div>
                <div>{{ roomRateInfo.childERateName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child F Rate') }}
                </div>
                <div>{{ roomRateInfo.childFRate }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Room Child F Rate Name') }}
                </div>
                <div>{{ roomRateInfo.childFRateName }}</div>
              </div>
            </div>
            <div class="column is-6">
              <div class="td">
                <div class="has-text-primary has-text-weight-bold">
                  {{ $t('Total Per Room Rate') }}
                </div>
                <div>{{ roomRateInfo.totalPerRoomRate }}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import Modal from '@/components/Modal';
import IconEdit from '@/components/icons/IconEdit';
import EditRoomInfo from './components/room-guest/EditRoomInfo';
import EditGuestInfo from './components/room-guest/EditGuestInfo';
import EditRoomOption from './components/room-guest/EditRoomOption';
import EditRoomRateInfo from './components/room-guest/EditRoomRateInfo';
import CalculatePax from './mixins/calculatePax';

export default {
  name: 'RoomGuest',
  components: {
    IconEdit,
    Modal,
    EditRoomInfo,
    EditGuestInfo,
    EditRoomOption,
    EditRoomRateInfo,
  },
  mixins: [CalculatePax],
  computed: {
    ...mapState('reservations', ['reservation']),
    reservedRoom() {
      return this.reservation.reservedRoom || {};
    },
    guest() {
      return this.reservedRoom.guests[0] || {};
    },
    roomRateInfo() {
      return (this.reservedRoom.rates && this.reservedRoom.rates[0]) || {};
    },
    roomInfo() {
      return this.reservedRoom.info || {};
    },
    roomOption() {
      return (this.roomInfo.optionList && this.roomInfo.optionList[0]) || {};
    },
  },
  methods: {
    asyncData() {
      this.$store.dispatch('reservations/get', { id: this.$route.params.id });
    },
  },
};
</script>

<i18n src="@/locales/components/reservations.json"></i18n>
